.v2-info {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 0 0 20px;
}

.v2-info__heading {
    @include font-h3;
}

.v2-info__title {
    padding: 10px 0;
    min-width: 120px;
    font-weight: bold;
    display: flex;
    position: relative;

    span {
        background-color: $color-white;
        padding-right: 20px;
        position: relative;
        z-index: 2;
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        top: 1.4em;
        left: 0;
        height: 1px;
        background-color: $color-grey;
        opacity: 0.2;
        z-index: 1;
    }

    &--success {
        color: $success-darker;

        &::before {
            background-color: $success-darker;
        }
    }

    &--warning {
        color: $warning-darker;

        &::before {
            background-color: $warning-darker;
        }
    }

    &--danger {
        color: $danger-darker;

        &::before {
            background-color: $danger-darker;
        }
    }
}

.v2-info__definition {
    padding: 10px 20px 10px 20px;
    margin: 0;
    margin-left: 5px;

    &--success {
        color: $success-darker;
    }

    &--warning {
        color: $warning-darker;
    }

    &--danger {
        color: $danger-darker;
    }

    &--flex {
        display: flex;
        flex-direction: column;
    }
}

.v2-info__not-set {
    color: $color-grey;
}

//  Info Icon
.v2-info-icon-block {
    display: flex;
}

.v2-info-icon-wrap {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px 0;
    margin-left: 1px;

    &--multiple {
        width: 30px;
    }
}

.v2-info-icon {
    display: flex;
}

.v2-info-icon__definition {
    padding: 5px 10px 5px;
}
