.ucr__hcp-mapview-breakdown-container--alert.ucr__hcp-mapview-breakdown-container--child:hover {
    background-color: mix($color-white, $color-red, 30%);
}

.ucr__hcp-mapview-breakdown-container--alert {
    background-color: $color-task-bg-alert;
    color: $color-light;

    .ucr__hcp-mapview-breakdown-name-and-postcode,
    .ucr__hcp-mapview-breakdown-duration,
    .ucr__hcp-mapview-breakdown-strikethrough,
    .ucr__hcp-mapview-breakdown-postcode,
    .ucr__hcp-mapview-breakdown-time-and-duration {
        color: $color-white;
    }
}

.ucr-mapview__main {
    padding-right: 25px;
    margin-top: 20px;
    padding-bottom: 50px;
    overflow: scroll;
    min-height: calc(100vh - 300px);
    width: 100%;
    position: relative;
    display: grid;
    padding-left: 30px;
    &--collapsed {
        grid-template-columns: 0px 1fr;
    }
    &--expanded {
        grid-template-columns: 470px 1fr;
    }
}

.ucr-mapview__col1 {
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    display: grid;
    width: 470px;
    &--collapsed {
        grid-template-columns: 0px;
    }
    &--expanded {
        grid-template-columns: 470px;
    }
    &--opened {
        height: 50%;
    }
    border: 1px solid #dddddd;
}
.ucr-mapview__expandFrame {
    position: absolute;
    height: calc(100% - 125px);
    width: 30px;
    right: 0px;
    left: 0px;
    z-index: 11;
}

.ucr-mapview__col2 {
    height: 100%;
}

.ucr-mapview__hcps {
    width: 480px;
    border: none;
    &--collapsed {
        visibility: hidden;
    }
    &--expanded {
        visibility: visible;
    }
}

.ucr-mapview__hcps > .ucr__calendar-hcps {
    border-right: none;
}

.ucr-mapview__nohcps {
    padding: 25px;
    text-align: center;
}

.popover-container-mapview {
    position: absolute;
    top: 263px;
    left: calc(100vw - 540px);
}

.ucr__hcp-mapview-hide-show-completed {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.ucr__hcp-mapview-hide-show-completed > .v2__button-name {
    text-align: center;
    position: relative;
    width: 100%;
}

.ucr__hcp-mapview-breakdown-container {
    flex-grow: 1;
    background-color: $color-light;
    padding-bottom: 10px;

    &--child:first-child {
        padding-top: 10px;
    }
    &--child {
        display: grid;
        grid-template-columns: 40px 2fr 1fr;
        width: 100%;
        position: relative;
        padding-bottom: 0px;
        cursor: pointer;
        padding-left: 20px;
        padding-right: 20px;
    }
    &--child:hover {
        background-color: rgba($color-blue-bg, 0.5);
    }
}

.ucr__hcp-mapview-breakdown--timecircle {
    width: 30px;
    height: 30px;
    letter-spacing: 0;
    color: #546e7a;
    text-align: center;
    opacity: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 3px solid #546e7a;
    border-radius: 20px;
    position: relative;
    span {
        position: absolute;
        top: 5px;
        left: 0px;
        width: 100%;
        font-weight: bold;
        font-size: 12px;
    }
    &--ARRIVED {
        border: 3px solid $job-status-arrived;
    }
    &--CURRENT {
        border: 3px solid $job-status-current;
    }

    &--warning {
        border-color: $color-hcp-text-warning;
        color: $color-hcp-text-warning;
    }
}

.ucr__hcp-mapview-breakdown-time-and-duration {
    text-align: right;
    letter-spacing: 0px;
    color: #3281ac;
    padding-right: 25px;

    &--warning {
        color: $color-hcp-text-warning;
    }
}

.ucr__hcp-mapview-breakdown-name-and-postcode {
    text-align: left;
    letter-spacing: 0px;
    color: #3281ac;
    opacity: 1;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0px;
}

.ucr__hcp-mapview-breakdown-postcode {
    letter-spacing: 0px;
    color: #546e7a;
    font-size: 10px;

    &--pl {
        padding-left: 24px;
    }
}

.ucr__hcp-mapview-breakdown-duration {
    letter-spacing: 0px;
    color: #546e7a;
    font-size: 10px;
    opacity: 1;
}

.ucr__hcp-mapview-dot-container {
    margin-top: 5px;
    padding-left: 20px;
    margin-bottom: 5px;
}

.ucr__hcp-mapview-dot {
    background: #546e7a 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    width: 4px;
    height: 4px;
    margin-top: 3px;
    margin-left: 12px;
}

.ucr__hcp-mapview-breakdown--statuscircle {
    height: 22px;
    width: 22px;
    border-radius: 20px;
    margin-left: 2px;
    margin-top: 2px;
    padding-bottom: -10px;
    &--CONTROLLER_ABORTED {
        background: $job-status-aborted 0% 0% no-repeat padding-box;
    }
    &--COMPLETED {
        background: $job-status-completed 0% 0% no-repeat padding-box;
    }
}

.ucr__hcp-mapview-breakdown-strikethrough {
    text-decoration: line-through;
    color: #7f939c;

    &--warning {
        color: $color-hcp-text-warning;
    }
}

.ucr__hcp-mapview-breakdown-arrivedTime {
    padding-left: 5px;
}

.ucr-mapview__icon {
    fill: $color-hcp-text-warning;
    display: inline;
    word-wrap: normal;

    position: absolute;
    top: 20px;
    left: 82px;
    width: 24px;
    height: 24px;
}

.ucr-mapview__breakdown-icon-wrap {
    display: flex;
    align-items: center;
    color: $color-hcp-text-warning;
}

.ucr-mapview__breakdown-icon {
    width: 24px;
    height: 24px;
    fill: $color-hcp-text-warning;
    display: inline;
    position: relative;
    top: 7px;
    word-wrap: normal;
}
