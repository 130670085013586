.ucr__side-panel {
    top: 0;
    height: 100%;
    position: absolute;
    display: flex;
    z-index: 1;

    pointer-events: none;

    &--left {
        right: 0;
        border-right: 1px solid $color-border-main;
    }
    &--right {
        left: 0;
        border-left: 1px solid $color-border-main;
    }

    &--show {
        z-index: 2;
    }
    &--hide {
        z-index: 1;
    }
}

.ucr__side-panel-wrapper {
    width: $side-panel;
    background-color: $color-white;
    flex-direction: column;
    display: flex;
    pointer-events: all;

    &--show {
        transition: visibility $duration-appearance step-start;
        visibility: visible;
    }

    &--hide {
        transition: visibility $duration-appearance step-end;
        visibility: hidden;
    }
}

.ucr__side-panel-header {
    padding-left: 20px;
    align-items: center;
    justify-content: space-between;
    display: flex;
}

.ucr__side-panel-title {
    @include font-h3;
    color: $color-blue-dark;
}

.ucr__side-panel-close {
    padding: 20px;
    width: 60px;
    height: 60px;
    transition: fill $duration-mouse-over $function-mouse-over;
    cursor: pointer;

    &--default {
        fill: $color-grey;
    }
    &--hovered {
        fill: $color-blue-dark;
    }
}

.ucr__side-panel-content {
    height: calc(100% - 60px);
    flex-direction: column;
    display: flex;
}

.ucr__side-panel-block {
    flex: 0 0 auto;
    margin-bottom: 12px;
    padding: 0 12px;

    &--scrollable {
        flex: 1 1 auto;
        overflow: hidden auto;
    }
}

.ucr__side-panel-container {
    padding: 3px 8px;
    min-height: 100%;
    position: relative;
}

.ucr__side-panel-container-placeholder {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include font-h4;
    color: $color-grey;
    background-color: $color-light-66;
    border: 1px dashed $color-grey;
    border-radius: $border-radius-md;
    transition: opacity $transition-mouse-over;
    align-items: center;
    justify-content: center;
    position: absolute;
    display: flex;
    pointer-events: none;
    opacity: 0;
    z-index: 2;

    &--hover {
        opacity: 1;
    }
}
.ucr__side-panel-legend-wrapper {
    padding-left: 8px;
}
.ucr__legend-panel-sub-section {
    display: flex;
    flex-direction: row;
}
.ucr__legend-panel-square-box-size {
    width: 20px;
    height: 20px;
}
.ucr__legend-panel-square-box-urgent {
    background-color: $color-task-bg-urgent;
}
.ucr__legend-panel-square-box-followUp {
    background-color: $color-task-bg-follow-up;
}
.ucr__legend-panel-square-box-adminTime {
    background-color: $color-task-bg-administrative;
}
.ucr__legend-panel-square-box-otherAdminTime {
    background-color: $color-blue-bg;
}
.ucr__legend-panel-square-box-coordinatorOrUrgentResponder {
    background-color: $color-task-bg-coordinator;
}
.ucr__legend-panel-square-box-any {
    background-color: $color-task-bg-alert;
}

.ucr__legend-panel-label {
    margin-left: 5px;
    width: 200px;

    &-map {
        margin-left: 10px;
        width: 200px;
    }
}

.ucr__legend-panel {
    &-light-grey {
        background-color: $color-legend-and-box-light-grey;
    }
    &-light-blue {
        background-color: $color-legend-and-box-light-blue;
    }
    &-light-green {
        background-color: $color-legend-and-box-light-green;
    }
    &-icon-map {
        width: 40px;
        display: grid;
        justify-content: center;
    }
}
.ucr__legend-panel-titles {
    @include font-h4;
}
.ucr__filter-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    background-color: $color-blue;
    color: $color-white;
    padding-left: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 10px;
    margin-bottom: 10px;

    &-title {
        @include font-h4;
    }

    &-icon {
        margin-left: 10px;
        fill: $color-white;
        display: flex;
        position: relative;
        word-wrap: normal;
    }
}
