.jobs-list {
    background-color: $fadedBackground;
    padding: 10px;
    min-height: 100%;
}

.jobs-list__item {
    position: relative;
    font-size: 12px;
    display: block;
    padding: 35px 10px 0;
    background-color: white;
    border-radius: 5px;
    border: solid 3px white;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}

.jobs-list__item--available {
    border-color: $available;

    .jobs-list__item-header {
        background: $available;
    }
}

.jobs-list__item--accepted {
    border-color: $accepted;

    .jobs-list__item-header {
        background: $accepted;
    }
}

.jobs-list__item--current {
    border-color: $current;

    .jobs-list__item-header {
        background: $current;
    }
}

.jobs-list__item--arrived {
    border-color: $arrived;

    .jobs-list__item-header {
        background: $arrived;
    }
}

.jobs-list__item--requireAttention {
    border-color: $requireAttention;
    background-color: $requireAttention;
    color: white;

    .jobs-list__item-header {
        background: $requireAttention;
    }

    .info-pair__heading {
        color: #e4dfdf;
    }

    a {
        color: #fff;
        text-decoration: underline;
    }
}

.jobs-list__item--active {
    border-color: #646464;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2) !important;
}

.jobs-list__icon {
    margin-right: 5px;
}

.jobs-list__item-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: white;
    font-size: 13px;
    padding: 5px 8px 7px;
    display: flex;
    align-items: center;

    .bp5-icon {
        margin-right: 5px;
    }
}

.jobs-list__item-header-info {
    margin-left: auto;
}
